.rpv-core__minimal-button {
  color: white !important;
}

.rpv-zoom__popover-target-arrow {
  border-color: white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}

.rpv-core__tooltip-body {
  display: none !important;
}