.bg-home {
  background-image: url("/public/images/home/bg-mdm.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-plans {
  background-image: url("/public/images/home/bg-plans.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-success {
  background-image: url("/public/images/home/suscription/bg-success.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}