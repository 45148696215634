.btn-bg-blue{ 
  background-color: var(--primary-color); 
} 

.btn-bg-blue:hover{ 
  background-color: transparent; 
  border: 2px solid var(--primary-color);
}

.btn-bg-yellow{ 
  background-color: var(--tertiary-color); 
}

.btn-bg-yellow:hover{ 
  background-color: transparent; 
  border: 2px solid var(--tertiary-color);
}

.btn-bg-red{ 
  background-color: var(--secondary-color); 
}

.btn-bg-red:hover{ 
  background-color: transparent; 
  border: 2px solid var(--secondary-color);
}