.input-line-blue {
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  border: var(--primary-color) 2px solid;
  letter-spacing: 0.2px;
  padding: 0 16px;
  height: 42px;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.input-line-blue:focus {
    box-shadow: 0 0 0 2px var(--primary-color);
    outline: none;
    transition: all 0.2s ease-in-out;
  }
  
  .input-line-yellow {
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  border: var(--tertiary-color) 2px solid;
  letter-spacing: 0.2px;
  padding: 0 16px;
  height: 42px;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.input-line-yellow:focus {
    box-shadow: 0 0 0 2px var(--tertiary-color);
    outline: none;
    transition: all 0.2s ease-in-out;
  }  
  
  .input-line-red {
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  border: var(--secondary-color) 2px solid;
  letter-spacing: 0.2px;
  padding: 0 16px;
  height: 42px;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.input-line-red:focus {
    box-shadow: 0 0 0 2px var(--secondary-color);
    outline: none;
    transition: all 0.2s ease-in-out;
  }