@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #245789;
  --secondary-color: #fa4443;
  --tertiary-color: #fde05a;
  --quaternary-color: #323232;
  accent-color: #245789;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-family: "Oswald", sans-serif;
  font-size: 52px;
}

h2 {
  font-family: "Oswald", sans-serif;
  font-size: 45px;
}

h3 {
  font-family: "Oswald", sans-serif;
  font-size: 38px;
}

h4 {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
}

h5 {
  font-family: "Oswald", sans-serif;
  font-size: 24px; 
}

p,
li {
  font-size: 17px;
  font-weight: 300;
}

button {
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

a {
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

section,
header,
footer {
  padding: 0 8%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 19px;
  }

  p {
    font-size: 16px;
  }

  section,
  header,
  footer {
    padding: 0 7%;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 31px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px; 
  }

  section,
  header,
  footer {
    padding: 0 6%;
  }

  p {
    font-size: 15px;
  }
}
